import { render, unmountComponentAtNode } from 'react-dom';
import React, { useEffect, useRef, useState, useCallback, useContext } from 'react';
import _ from 'lodash';

const Sizes = ({attributes, category, coef, selectedColors, defaultSizes, quantities, _onChangeQuantity, _handleRemoveQuantity}) => {

   if(_.size(selectedColors) == 0){
      //return null;
   }

   const getDefaultSizes = () => {
      let values = [];
      if(_.size(attributes) == 0) return values;
      for(let size of defaultSizes){
         if(_.find(attributes, ['size', size])){
            values.push(size);
         }
      }
      return values;
   }


   console.log(getDefaultSizes())

   const _getSizesFromColor = (color) => {
      return _.filter(attributes, ['colorsReference', color.colorsReference]).map((item) => {
         return item.size;
      });
   }

   const getLabels = () => {
      const getSizeNames = () => {
         return _.map(getDefaultSizes(), (size) => {
            return (
               <li key={size}>{size}</li>
            );
         });
      }
      return (
         <ul>{getSizeNames()}</ul>
      );
   }

   const getColorName = ({colors}) => {
      return _.get(_.first(colors), 'name');
   }

   const getColorValue = ({colors}) => {
      return _.get(_.first(colors), 'value');
   }

   const getValue = (reference) => {
      const quantityItem = _.find(quantities, ['reference', reference]);
      return quantityItem ? quantityItem.qte : 0;
   }

   const getQuantity = () => {
      return _.sumBy(quantities, 'qte');
   }

   const getCoef = () => {
      const categoryProductPrices = _.get(category, 'productPrices');
      if(categoryProductPrices){
         const categoryProductPricesFiltered = _.filter(categoryProductPrices, (price) => {
            const matchPrintPriceRange = (_.get(price, 'min') <= getQuantity() && _.get(price, 'max') >= getQuantity()) ? true : false;
            return matchPrintPriceRange ? true : false;
         });
         if(_.size(categoryProductPricesFiltered) > 0){
            const categoryProductPrice = _.first(categoryProductPricesFiltered);
            return _.get(categoryProductPrice, 'coef');
         } else {
            return coef;
         }
      } else {
         return coef;
      }
   }

   const getPriceUnit = (price) => {
      if(getQuantity() == 0){
         return 0;
      }
      return _.round(price / getCoef(), 2);
   }

   const getAmount = ({colorsReference, price}) => {
       const items = _.filter(quantities, (line) => {
          const [ref, size] = _.split(line.reference, '|');
          return ref == colorsReference ? true: false;
       });
       const quantity = _.sumBy(items, 'qte');
       const priceUnit = getPriceUnit(price);
       return _.round(quantity * priceUnit, 2);
   }

   const getLines = () => {
      const getInputs = (color) => {
         return _.map(getDefaultSizes(), (size) => {
            const referenceInput = color.colorsReference + '|' + size;
            const colorMain = _.size(color.colors) > 0 ? color.colors[0].value : '';
            const colorMainName = _.size(color.colors) > 0 ? color.colors[0].name : '';
            const tone = color.tone;
            const price = color.price;
            const payload = {
               sku: color.reference,
               price,
               tone,
               color: colorMain
            };
            return (
               <li key={referenceInput}>
                  <div className="name">Taille : {size}</div>
                  <input onChange={(e) => _onChangeQuantity(e, referenceInput, payload)} type="text" name="" value={getValue(referenceInput)} />
               </li>
            );
         });
      }
      return _.map(selectedColors, (color, index) => {
         return (
            <div className="line" key={index}>
               <div className="currentColor">
                  <span style={{backgroundColor: '#' + getColorValue(color)}}></span>
               </div>
               <ul>{getInputs(color)}</ul>
               <div className="amount">
                  <div className="unit">
                     <div className="name">Prix/u : </div> <span>{getPriceUnit(color.price)}</span>€
                  </div>
                  <div className="all">
                     <div className="name">Total HT : </div> <span>{getAmount(color)}</span>€
                  </div>
                  <div className="delete"><a href="#" onClick={() => _handleRemoveQuantity(color)}><i className="far fa-trash-alt"></i> <div className="name">Supprimer</div></a></div>
               </div>
            </div>
         );
      });
   }

   return (
      <div className="configurator__sizes">
         <div className="name">2. Définissez les quantités désirées</div>
         <div className="labels">
            <ul>{getLabels()}</ul>
            <div className="amount">
               <div className="unit">Prix/u</div>
               <div className="all">Total HT</div>
            </div>
         </div>
         <div className="values">
            {getLines()}
         </div>
      </div>
   );

}

export default Sizes;
