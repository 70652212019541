import createDataContext from './createDataContext';
import Api from '../api';
import _ from 'lodash';

const configuratorReducer = (state, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const { Context, Provider } = createDataContext(
  configuratorReducer,
  { },
  { items: [] }
);
