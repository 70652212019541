import { render, unmountComponentAtNode } from 'react-dom';
import React, { useEffect, useRef, useState, useCallback, useContext } from 'react';
import { Context as ConfiguratorContext } from "./context/ConfiguratorContext";
import { Provider as ConfiguratorProvider } from "./context/ConfiguratorContext";
import _ from 'lodash';

import Sizes from './components/sizes.jsx';
import Colors from './components/colors.jsx';
import Custom from './components/custom.jsx';
import Sum from './components/sum.jsx';

const Configurator = ({product, category, prices, features}) => {
   

   const {id, attributes, coef, price} = product;
   const colors = _.uniqBy(attributes, 'colorsReference');
   const defaultSizes = ['S','M','L','XL','XXL','2XL','XXXL','3XL'];

   const [selectedColors, setSelectedColors] = useState([]);

   const [quantities, setQuantities] = useState([]);
   const [quantity, setQuantity] = useState(0);
   const [quantityHard, setQuantityHard] = useState(0);
   const [quantitySoft, setQuantitySoft] = useState(0);

   const [customizes, setCustomizes] = useState([]);
   const [hasSoftProduct, setHasSoftProduct] = useState(false);
   const [hasHardProduct, setHasHardProduct] = useState(false);

   useEffect(() => {

      const quantitySum = _.sumBy(quantities, 'qte');

      const quantityHardSum = _.sumBy(_.filter(quantities, ['tone', 'hard']), 'qte');
      const quantitySoftSum = _.sumBy(_.filter(quantities, ['tone', 'soft']), 'qte');

      setQuantity(quantitySum);
      setQuantityHard(quantityHardSum);
      setQuantitySoft(quantitySoftSum);

      setHasHardProduct(quantityHardSum > 0);
      setHasSoftProduct(quantitySoftSum > 0);

   }, [quantities]);

   const _handleSelectColor = (color) => {
      let selectedColorsClone = [...selectedColors];
      const selectedColorsIndex = _.findIndex(selectedColorsClone, ['colorsReference', color.colorsReference]);
      if(selectedColorsIndex >= 0){
         _.pullAt(selectedColorsClone, [selectedColorsIndex]);
         let quantitiesClone = [..._.reject(quantities, ['sku', color.reference])];
         setQuantities([...quantitiesClone]);
      } else {
         selectedColorsClone.push(color);
      }
      setSelectedColors([
         ...selectedColorsClone
      ]);
   }

   const _onChangeQuantity = (e, reference, payload) => {
      const qte = _.toNumber(e.target.value);
      let quantitiesClone = [...quantities];
      const quantityIndex = _.findIndex(quantitiesClone, ['reference', reference]);
      if(quantityIndex >= 0){
         quantitiesClone[quantityIndex].qte = qte;
      } else {
         quantitiesClone.push({...payload, reference, qte});
      }
      setQuantities([...quantitiesClone]);
   }

   const _handleRemoveQuantity = (color) => {
      _handleSelectColor(color);
      let quantitiesClone = [..._.reject(quantities, ['sku', color.reference])];
      setQuantities([...quantitiesClone]);
   }

   const _handleAddCustomize = (payload) => {
      setCustomizes([...customizes, payload]);
   }

   const _handleUpdateCustomize = (payload) => {
      let customizesClone = [...customizes];
      let customizesIndex = _.findIndex(customizesClone, function(customize) {
         return _.get(customize, 'canva.id.id') == _.get(payload, 'canva.id.id');
      });
      customizesClone[customizesIndex] = payload;
      setCustomizes([...customizesClone]);
   }

   const _handleDeleteCustomize = (payload) => {
      let customizesClone = [...customizes];
      let customizesIndex = _.findIndex(customizesClone, function(customize) {
         return _.get(customize, 'canva.id.id') == _.get(payload, 'canva.id.id');
      });
      if(customizesIndex >= 0){
         _.pullAt(customizesClone, [customizesIndex]);
         setCustomizes([...customizesClone]);
      }
   }

   const defaultProps = {
      id,
      hasSoftProduct,
      hasHardProduct,
      quantity,
      quantityHard,
      quantitySoft,
      features,
      prices,
      coef,
      price,
      category,
      attributes,
      colors,
      selectedColors,
      _handleSelectColor,
      defaultSizes,
      quantities,
      _onChangeQuantity,
      _handleRemoveQuantity,
      customizes,
      _handleAddCustomize,
      _handleUpdateCustomize,
      _handleDeleteCustomize
   };

   return (
      <ConfiguratorProvider>
        <div className='configurator'>
           <Colors {...defaultProps} />
           <Sizes {...defaultProps} />
           <Custom {...defaultProps} />
           <Sum {...defaultProps} />
        </div>
      </ConfiguratorProvider>
   );

}

class ConfiguratorElement extends HTMLElement {
   connectedCallback() {
      const product = this.dataset.product || false;
      const category = this.dataset.category || false;
      const prices = this.dataset.prices || false;
      const features = this.dataset.features || false;
      render(
         <Configurator
            product={product?JSON.parse(product):{}}
            category={category?JSON.parse(category):{}}
            prices={prices?JSON.parse(prices):{}}
            features={features?JSON.parse(features):{}}
         />, this
      );
   }

   disconnectedCallback() {
      unmountComponentAtNode(this);
   }
}

customElements.define('configurator-element', ConfiguratorElement);
