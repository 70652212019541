/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './sass/app/index.scss';

// start the Stimulus application
import './bootstrap';

/* Import Own Components */
import './js/configurator/index.jsx';

import numeral from 'numeral';

const $ = require('jquery');
const axios = require('axios');

$(function(){

   $('header#header .actions li.handle-nav').on('click', function(){
      $('nav#nav-mobile').addClass('active');
      $('div#nav-mobile-overlay').addClass('active');
   });

   $('div#nav-mobile-overlay').on('click', function(){
      $('nav#nav-mobile').removeClass('active');
      $('div#nav-mobile-overlay').removeClass('active');
   });

   $('nav#nav-mobile button.login').on('click', function(){
      $('nav#nav-mobile').removeClass('active');
      $('div#nav-mobile-overlay').removeClass('active');
      const modalRoot = document.getElementById('loginModal');
      if(modalRoot){
         const modal = new bootstrap.Modal(modalRoot, {});
         modal.show();
      }
      return false;
   });

   $('*[data-toggle="url"]').each(function(){
      $(this).on('click', function(){
         const url = $(this).data('url');
         window.location.href = url;
      });
   });

   // -> handle thumbs image
   let _product = $('section#product');
   let _mediaProduct = _product.find('div.media');
   let _thumbsProduct = _product.find('div.thumbs');
   _thumbsProduct.find('li').hover(function(){
      let url = $(this).data('medium');
      _mediaProduct.find('img').attr('src', url);
   });

   _product.on('mouseover', '.configurator__colors ul.colors li',  function(){
      const reference = $(this).data('reference');
      const item = $('.thumbs ul li.thumbs-attribute-' + reference);
      if(item.length > 0){
         let url = item.data('medium');
         _mediaProduct.find('img').attr('src', url);
      }
   });

   //-> Show add cart modal
   const addCartItem = document.getElementById('addCartModal');
   if(addCartItem){
      const addCartModal = new bootstrap.Modal(addCartItem, {});
      const closeButton = addCartItem.querySelector('button.close');
      closeButton.addEventListener('click', () => {
         addCartModal.hide(); // Fermer la modal lorsque le bouton est cliqué
      });
      addCartModal.show();
      
   }

   /**
    * Loading cart
    */
   const cartLoadingFetch = (item) => {
      const url = item.getAttribute('data-url');
      axios.get(url).then((response) => {
         const {data} = response;
         if(data.count > 0){
            let notification = document.createElement('span');
            notification.textContent = data.count;
            notification.className = 'notification';
            item.append(notification);
         }
      }).catch((e) => {

      });
   }

   const cartLoading = document.getElementsByClassName('loading-cart');
   if(cartLoading.length > 0){
      cartLoadingFetch(cartLoading[0]);
   }

   let _cart = $('section#cart');
   let _cartDelay = _cart.find('div.delay');
   let _cartDelivery = _cart.find('div.delivery');
   let _cartAccount = _cart.find('div.account');
   let _cartConfiguration = _cart.find('div.configuration');

   _cartAccount.find('input#form_sameAddress').on('change', function(){

		let checked = $(this).is(':checked');
		let billingItems = _cartAccount.find('.item-billing');

		billingItems.each(function(){
			let name = $(this).attr('name');
			let nameSearch = _.replace(name, 'billing', 'shipping');

			let value = checked?$('.item-billing[name="' + name + '"]').val():'';
			$('.item-shipping[name="' + nameSearch + '"]').val(value);
		});

		if(checked){
			_cartAccount.find('div.shipping').hide();
		} else {
			_cartAccount.find('div.shipping').show();
		}

	});

	_cartAccount.find('.item-billing').on('change', function(){

		let checked = _cartAccount.find('input#form_sameAddress').is(':checked');
		if(checked){

			let name = $(this).attr('name');
			let nameSearch = _.replace(name, 'billing', 'shipping');

			let value = $('.item-billing[name="' + name + '"]').val();
			$('.item-shipping[name="' + nameSearch + '"]').val(value);

		}

	});

   _cartConfiguration.find('div.selector').each(function(){
		const that = $(this);
		$(this).find('li').on('click', function(){
			let parent = $(this).parents('div.selector');
			parent.find('li').removeClass('active');
			$(this).addClass('active');
			let value = $(this).data('value');
			parent.find('input[type="hidden"]').val(value);
			updateDisplaySelector(that);
		});
	});

	$('input[type="file"]').on('change', function(e){
		$(this).siblings('label').html('Fichier : ' + e.target.files[0].name + '<a href="#"><i class="fas fa-times"></i></a>');
	});

	$('label.custom-file-upload').on('click', 'a', function(){
		$(this).parent().html('<i class="fas fa-upload"></i> Téléverser mon fichier');
		$(this).siblings('input').val('');
		return false;
	});

	$('label.unique-file-upload').on('click', 'a', function(){
		$(this).parent().html('<i class="fas fa-upload"></i> Téléverser mon fichier');
		$(this).siblings('input').val('');
		return false;
	});

	$('label.customFile').bind('click', function(e) {
		$(this).siblings('input').trigger('click');
		return false;
	});

	$('label.uniqueFile').bind('click', function(e) {
		$(this).siblings('input').trigger('click');
		return false;
	});

   function amountFormat(amount) {
      return numeral(amount).format('0,0.00') + '€';
   }

   function updateDisplaySelector(element) {
		let id = element.data('id');
		element.find('li').each(function(){
			let type = $(this).data('type');
			let identifier = type + '-' + id;
			if($(this).hasClass('active')){
				$('div#' + identifier).addClass('active');
			} else {
				$('div#' + identifier).removeClass('active');
			}
		});
	}

   function handleUpdateDeliveryAmount() {

		let _cardAmountWithoutTaxValue = _cart.find('div.more ul li.value.amountItemsWithoutTax').data('value');
		let _cartDelayValue = _cartDelay.find('div.selected').data('price');
		let _cartDeliveryValue = _cartDelivery.find('div.selected').data('price');

		$('div.more div.summary li.value.delay').html(amountFormat(_cartDelayValue));
		$('div.more div.summary li.value.delivery').html(amountFormat(_cartDeliveryValue));

		let _amountAdditionnal = parseFloat(_cartDelayValue) + parseFloat(_cartDeliveryValue);
		let amountWithoutTax = parseFloat(_cardAmountWithoutTaxValue) + _amountAdditionnal;
		let amountWithTax = amountWithoutTax + (amountWithoutTax*0.20);
		let amountTax = amountWithTax - amountWithoutTax;

		$('div.more div.summary li.value.amountWithoutTax').html(amountFormat(amountWithoutTax));
		$('div.more div.summary li.value.amountTax').html(amountFormat(amountTax));
		$('div.more div.summary li.value.amountWithTax').html(amountFormat(amountWithTax));

	}

   _cartDelay.find('div.option').on('click', function(){
      let options = $(this).parents('div.options');
      let value = parseInt($(this).data('id'));
      options.find('div.option').removeClass('selected');
      $(this).addClass('selected');
      _cartDelay.find('input').val(value);
		handleUpdateDeliveryAmount();
   });

   _cartDelivery.find('div.option').on('click', function(){
      let options = $(this).parents('div.options');
      let value = parseInt($(this).data('id'));
      options.find('div.option').removeClass('selected');
      $(this).addClass('selected');
      _cartDelivery.find('input').val(value);
		handleUpdateDeliveryAmount();
   });


   $('.modal-checkout-upload .selector ul li').on('click', function(){
      const _parent = $(this).parents('.modal-checkout-upload');
      const _selector = $(this).parents('.selector');
      const $value = $(this).data('value');
      _parent.find('.upload .file, .upload .text').removeClass('active');
      _parent.find('.upload .' + $value).addClass('active');
      _selector.find('ul li').removeClass('active');
      _selector.find('ul li.item-'+ $value).addClass('active');
      _selector.find('input[type="hidden"]').val($value);
   });


});
