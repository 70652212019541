import { render, unmountComponentAtNode } from 'react-dom'
import React, { useEffect, useRef, useState, useCallback, useContext } from 'react'
import _ from 'lodash'
import numeral from 'numeral'

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Overlay from 'react-bootstrap/Overlay'

const Tooltip = ({tooltip}) => {
   const [show, setShow] = useState(false)
   const target = useRef(null)
   return (
      <>
         <Button ref={target} onClick={() => setShow(!show)}>
            <i class="fa-sharp fa-solid fa-circle-info"></i>
         </Button>
         <Overlay target={target.current} show={show} placement="bottom">
          {({ placement, arrowProps, show: _show, popper, ...props }) => (
             <div
               {...props}
               style={{
                 width: '180px',
                 position: 'absolute',
                 backgroundColor: 'black',
                 padding: '2px 10px',
                 color: 'white',
                 borderRadius: 3,
                 zIndex: 1051,
                 fontSize: '12px',
                 paddingTop: '8px',
                 paddingBottom: '8px',
                 ...props.style,
               }}
             >
               {tooltip}
             </div>
          )}
         </Overlay>
      </>
   )
}

const Custom = ({features, category, prices, quantities, quantityHard, quantitySoft, _handleAddCustomize, _handleUpdateCustomize, _handleDeleteCustomize, customizes}) => {

   const getQuantity = () => {
      return _.sumBy(quantities, 'qte')
   }

   if(getQuantity() == 0){
      //return null
   }

   const [show, setShow] = useState(false)
   const [error, setError] = useState(false)

   const handleClose = () => setShow(false)
   const handleShow = () => setShow(true)

   const [stepIndex, setStepIndex] = useState(0)
   const [pricesFiltered, setPricesFiltered] = useState([])
   const [mode, setMode] = useState('new')

   const [payload, setPayload] = useState({
      canva: null,
      color: null,
      uniq: false,
      printMethod: null
   })

   useEffect(() => {
      _handlePricesList()
   }, [payload, quantities])

   useEffect(() => {
      if(error) {
         setTimeout(() => {
            setError(false)
         }, 2000)
      }
   }, [error])

   const steps = [
      {identifier: 'canvas', field: 'canva', title: 'Emplacements', tooltip: false, options: 'canvas'},
      {identifier: 'colors', field: 'color', title: 'Nombre de couleurs', tooltip: false, options: 'colors'},
      {identifier: 'uniq', field: 'uniq', title: 'Personnalisation nominative', tooltip: 'Chaque impression est unique, pour les noms et numéros différent sur chaque produit.', options: [
         {value: false, name: 'Non'},
         {value: true, name: 'Oui'}
      ]},
      {identifier: 'printMethods', field: 'printMethod', title: 'Impression', tooltip: false, options: 'printMethods'},
   ]

   const nextStep = () => {
      let increment = 1
      if(steps[stepIndex] && steps[stepIndex].identifier == 'colors'){
         increment = 2
      }
      setStepIndex(stepIndex + increment)
   }

   const handleNew = () => {
      if(getQuantity() == 0){
         setError(true)
         return null
      } else {
         handleShow()
         _resetPayload()
         setStepIndex(0)
         setMode('new')
      }

   }

   const getPricesFilteredByTone = (data, tone) => {
      const quantitiesByTone = {
         soft: quantitySoft,
         hard: quantityHard
      }
      const quantity = _.get(quantitiesByTone, tone)
      const pricesFiltered = _.filter(prices, (price) => {
         const matchTone = _.get(price, 'fabric_tone') == tone
         const matchCanva = _.get(price, 'canva.id') == _.get(data, 'canva.id') ? true : false
         const matchColor = _.get(price, 'colors') == _.get(data, 'color') ? true : false
         const matchPrintPriceRange = (_.get(price, 'print_price_range.min') <= quantity && _.get(price, 'print_price_range.max') >= quantity) ? true : false
         return matchTone && matchCanva && matchColor && matchPrintPriceRange ? true : false
      })
      return pricesFiltered
   }

   const _handlePricesList = () => {
      if(!_.isNull(payload.canva) && !_.isNull(payload.color)){
         const pricesFiltered = [...getPricesFilteredByTone(payload, 'soft'), ...getPricesFilteredByTone(payload, 'hard')]
         setPricesFiltered(pricesFiltered)
      } else {
         setPricesFiltered([])
      }
   }

   const getPrices = (data) => {
      if(!_.isNull(data.canva) && !_.isNull(data.color) && !_.isNull(data.printMethod)){
         return [...getPricesFilteredByTone(data, 'soft'), ...getPricesFilteredByTone(data, 'hard')]
      } else {
         return []
      }
   }

   const _handleSelected = (field, item) => {
      const payloadClone = {...payload}
      const currentState = payloadClone[field]
      payloadClone[field] = item
      if(field != 'printMethod'){
         payloadClone['printMethod'] = null
      }
      setPayload(payloadClone)
      if(!currentState){
         nextStep()
      }
   }

   const _resetPayload = () => {
      setPayload({
         canva: null,
         color: null,
         uniq: false,
         printMethod: null
      })
   }

   const amountFormat = (amount) => {
      return numeral(amount).format('0,0.00') + '€'
   }

   const getOptionPrintMethodDescription = (prices) => {
      if(_.size(prices) == 0){
         return 'Non disponible'
      }
      const getFabricTone = (tone) => {
         const tones = {
            soft: 'Textiles blancs',
            hard: 'Textiles couleurs'
         }
         return _.get(tones, tone)
      }
      const result = _.map(prices, (price) => {
         return getFabricTone(price.fabric_tone) + ' : ' + _.round(price.sell_price / 0.76, 2) + '€/u'
      })
      return _.isArray(result) ? result.join(', ') : result
   }

   const getPrintMethodSum = (prices) => {

      const getAmount = () => {
          const priceSoft = _.find(prices, ['fabric_tone', 'soft'])
          const priceHard = _.find(prices, ['fabric_tone', 'hard'])
          const amountSoft = priceSoft ? _.round(priceSoft.sell_price / 0.76, 2) * quantitySoft : 0
          const amountHard = priceHard ? _.round(priceHard.sell_price / 0.76, 2) * quantityHard : 0
          const amount = amountSoft + amountHard
          return _.round(amount, 2)
      }

      const getAmountUnit = () => {
         return _.round(getAmount() / getQuantity(), 2)
      }
      if(_.size(prices) > 0){
         return (
            <div className="amount">
               <div className="unit">Prix/u : {amountFormat(getAmountUnit())}</div>
               <div className="total">Total HT : {amountFormat(getAmount())}</div>
            </div>
         )
      }
      return ''
   }

   const getOptionPrintMethod = (item, field) => {


      const prices = _.filter(pricesFiltered, (price) => {
         return (_.get(price, 'print_method.id') == item.id && _.get(price, 'sell_price')  > 0) ? true : false
      })

      const isActive = () => {
         if(field == 'color'){
            return item == payload[field] ? true : false
         }
         return _.get(item, 'id') == _.get(payload, field + '.id') ? true : false
      }
      const isRecommanded = () => {
         const printMethodsAdvise = _.get(category, 'printMethodsAdvise')
         if(printMethodsAdvise){
            const printMethodsAdviseFiltered = _.filter(printMethodsAdvise, (printMethodAdvise) => {
               return _.get(printMethodAdvise, 'id') == _.get(item, 'id') ? true : false
            })
            return _.size(printMethodsAdviseFiltered) > 0 ? true : false
         }
         return false
      }
      const getAdvise = () => {
         if(isRecommanded()){
            return (
               <div className="advise">Recommandé</div>
            )
         }
         return null
      }

      const getContent = () => {
         if(payload.uniq && !item.uniq){
            return (
               <li key={_.get(item, 'id')} className={'disabled'}>
                  <div className="name">{item.name}</div>
                  <div className="description">Non disponible</div>
               </li>
            )
         }
         console.log(prices)
         if(_.size(prices) > 0){
            return (
               <li key={_.get(item, 'id')} className={isActive() ? 'active' : ''} onClick={() => _handleSelected(field, item)}>
                  <div className="name">{item.name} {getAdvise()}</div>
                  <div className="description">{getOptionPrintMethodDescription(prices)}</div>
               </li>
            )
         }
         return (
            <li key={_.get(item, 'id')} className={'disabled'}>
               <div className="name">{item.name}</div>
               <div className="description">Non disponible</div>
            </li>
         )
      }

      return {
         isDisabled: _.size(prices) > 0 ? false : true,
         isRecommanded: isRecommanded(),
         content: getContent()
      }

   }

   const getSteps = () => {
      const rendersSteps = () => {
         return _.map(steps, ({identifier, field, title, tooltip, options}, index) => {
            if(stepIndex >= index){
               const getToolTip = () => {
                  if(tooltip){
                     return (
                        <Tooltip tooltip={tooltip} />
                     )
                  }
                  return null
               }
               const getTitle = () => {
                  return (
                     <div className="label">{title} {getToolTip()}</div>
                  )
               }
               const getOptions = () => {
                  if(_.isArray(options)){
                     return _.map(options, (option) => {
                        const {value, name} = option
                        return (
                           <li className={value == payload[field] ? 'active' : ''} onClick={() => _handleSelected(field, value)}>{name}</li>
                        )
                     })
                  }
                  const values = _.get(features, identifier)
                  if(_.isArray(values)){
                     if(identifier == 'printMethods'){
                        const getItems = () => {
                           const itemsFiltered = values
                           return _.map(itemsFiltered, (item) => {
                              return getOptionPrintMethod(item, field)
                           })
                        }
                        const valuesPrintMethod = _.orderBy(getItems(), ['isRecommanded'], ['desc'])
                        const valuesPrintMethodFiltered = _.filter(valuesPrintMethod, ['isDisabled', false])
                        if(_.size(valuesPrintMethodFiltered) == 0){
                           return (
                              <div>Aucune impression disponible pour cette configuration.</div>
                           )
                        }
                        return _.map(valuesPrintMethodFiltered, ({content}) => {
                           return content
                        })
                     } else {
                        return _.map(values, (item) => {
                           const isActive = () => {
                              if(field == 'color'){
                                 return item == payload[field] ? true : false
                              }
                              return _.get(item, 'id') == _.get(payload, field + '.id') ? true : false
                           }
                           const isBlocked = () => {
                              if(identifier == 'canvas'){
                                 const isUsed = _.findIndex(customizes, function(o){
                                    return _.get(o, 'canva.id') == item.id ? true : false
                                 })
                                 if(mode == 'edit' || isUsed >= 0){
                                    return true
                                 } else {
                                    return false
                                 }
                              } else {
                                 return false
                              }
                           }
                           if(isBlocked()){
                              return (
                                 <li className={'blocked'} onClick={() => {}}>{item.name}</li>
                              )
                           }
                           return (
                              <li className={isActive() ? 'active' : ''} onClick={() => _handleSelected(field, item)}>{item.name ? item.name : (item >= 9 ? item + '+' : item)}</li>
                           )
                        })
                     }
                  }
                  return null
               }
               return (
                  <div key={index}>
                     {getTitle()}
                     <div className={['options', identifier].join(' ')}>
                        <ul>
                           {getOptions()}
                        </ul>
                     </div>
                  </div>
               )
            }
            return null
         })
      }
      return (
         <div className={'modal__configurator__custom'}>{rendersSteps()}</div>
      )
   }

   const _handleSubmit = () => {
      if(mode == 'edit') {
         _handleUpdateCustomize(payload)
      } else {
         _handleAddCustomize(payload)
      }
      _resetPayload()
      handleClose()
   }

   const getSubmit = () => {
      if(!_.isNull(payload.printMethod)){
         return (
            <button onClick={() => _handleSubmit()} className={'btn btn-default btn-stretch'}>Ajouter</button>
         )
      }
      return null
   }

   const _handleUpdate = (customize) => {
      setPayload(customize)
      setStepIndex(5)
      setMode('edit')
      handleShow()
   }

   const getContent = () => {
      return _.map(customizes, (customize) => {
         const prices = _.filter(getPrices(customize), (price) => {
            return _.get(price, 'print_method.id') == _.get(customize, 'printMethod.id') ? true : false
         })
         return (
            <li>
               <div className="name">{_.get(customize, 'canva.name')}</div>
               <div className="options">
                  <ul>
                     <li>Impression : {_.get(customize, 'printMethod.name')}</li>
                     <li>Couleur : {_.get(customize, 'color')}</li>
                  </ul>
               </div>
               <div className="priceCustom">{getOptionPrintMethodDescription(prices)}</div>
               <div className="actions">
                  <button onClick={() => _handleUpdate(customize)}><i class="fas fa-pencil-alt"></i></button>
                  <button onClick={() => _handleDeleteCustomize(customize)}><i class="far fa-trash-alt"></i></button>
               </div>
               <div className="prices">
                  {getPrintMethodSum(prices)}
               </div>
            </li>
         )
      })
   }

   const getAction = () => {
      /*
      if(getQuantity() == 0){
         return null
      }
      */
      const getErrorMessage = () => {
         return error ? <div className="error" style={{color: 'red', fontSize: 13, marginBottom: 12}}>Veuillez sélectionner une couleur.</div> : null
      }
      return (
         <>
         {getErrorMessage()}
         <button onClick={() => handleNew()} className={'btn btn-default btn-stretch'}>Ajouter un marquage</button>
         </>
      )
   }

   return (
      <>
         <div className="configurator__custom">
            <div className="name">3. Personnalisez votre produit</div>
            <div className="content">
               <p>Le transfert des fichiers se fait après la validation du panier, avant de réaliser le paiement. Un visuel du produit avec votre logo (BAT) vous sera envoyer avant l'impression de votre commande.</p>
               <ul>
                  {getContent()}
               </ul>
            </div>
            {getAction()}
         </div>
         <Modal className={'modal__configurator'} show={show} onHide={handleClose}>
           <Modal.Header closeButton>
             <Modal.Title>Ajouter une personnalisation</Modal.Title>
           </Modal.Header>
           <Modal.Body>
              {getSteps()}
           </Modal.Body>
           <Modal.Footer>
             {getSubmit()}
           </Modal.Footer>
         </Modal>
      </>
   )
}

export default Custom
