import { render, unmountComponentAtNode } from 'react-dom';
import React, { useEffect, useRef, useState, useCallback, useContext } from 'react';
import _ from 'lodash';
import numeral from 'numeral';

const Sum = ({quantities, category, coef, id, customizes, prices, quantity, quantityHard, quantitySoft}) => {

   const getQuantity = () => {
      return _.sumBy(quantities, 'qte');
   }

   if(getQuantity() == 0){
      return null;
   }

   const getPricesFilteredByTone = (data, tone) => {
      const quantitiesByTone = {
         soft: quantitySoft,
         hard: quantityHard
      };
      const quantity = _.get(quantitiesByTone, tone);
      const pricesFiltered = _.filter(prices, (price) => {
         const matchTone = _.get(price, 'fabric_tone') == tone;
         const matchCanva = _.get(price, 'canva.id') == _.get(data, 'canva.id') ? true : false;
         const matchColor = _.get(price, 'colors') == _.get(data, 'color') ? true : false;
         const matchPrintPriceRange = (_.get(price, 'print_price_range.min') <= quantity && _.get(price, 'print_price_range.max') >= quantity) ? true : false;
         const matchPrintMethod = _.get(price, 'print_method.id') == _.get(data, 'printMethod.id') ? true : false;
         return matchTone && matchCanva && matchColor && matchPrintPriceRange && matchPrintMethod ? true : false;
      });
      return pricesFiltered;
   }

   const getPrices = (data) => {
      if(!_.isNull(data.canva) && !_.isNull(data.color) && !_.isNull(data.printMethod)){
         return [...getPricesFilteredByTone(data, 'soft'), ...getPricesFilteredByTone(data, 'hard')];
      } else {
         return [];
      }
   }

   const getCoef = () => {
      const categoryProductPrices = _.get(category, 'productPrices');
      if(categoryProductPrices){
         const categoryProductPricesFiltered = _.filter(categoryProductPrices, (price) => {
            const matchPrintPriceRange = (_.get(price, 'min') <= getQuantity() && _.get(price, 'max') >= getQuantity()) ? true : false;
            return matchPrintPriceRange ? true : false;
         });
         if(_.size(categoryProductPricesFiltered) > 0){
            const categoryProductPrice = _.first(categoryProductPricesFiltered);
            return _.get(categoryProductPrice, 'coef');
         } else {
            return coef;
         }
      } else {
         return coef;
      }
   }

   const getPriceUnit = () => {
      const price = 0;
      return _.round(price / getCoef(), 2);
   }

   const getProductsAmount = () => {
       let amount = 0;
       _.forEach(quantities, ({qte, price}) => {
          const priceUnit = _.round(price / getCoef(), 2);
          amount += _.round(qte * priceUnit, 2);
       });
       return amount;
   }

   const getCustomizeAmount = () => {
       const amount = _.map(customizes, (customize) => {
          const prices = getPrices(customize);
          if(_.size(prices) > 0){
             const priceSoft = _.find(prices, ['fabric_tone', 'soft']);
             const priceHard = _.find(prices, ['fabric_tone', 'hard']);
             const amountSoft = priceSoft ? _.round(priceSoft.sell_price / 0.76, 2) * quantitySoft : 0;
             const amountHard = priceHard ? _.round(priceHard.sell_price / 0.76, 2) * quantityHard : 0;
             return amountSoft + amountHard;
          } else {
             return 0;
          }
       });
       return _.round(_.sum(amount), 2);
   }

   const getAmountWithoutTax = () => {
      return _.round(getProductsAmount() + getCustomizeAmount(), 2);
   }

   const getAmountWithoutTaxUnit = () => {
      return _.round(getAmountWithoutTax() / getQuantity(), 2);
   }

   const getAmountTax = () => {
      return _.round(getAmountWithoutTax() * 0.20, 2);
   }

   const getAmountWithTax = () => {
      return getAmountWithoutTax() + getAmountTax();
   }

   const amountFormat = (amount) => {
      return numeral(amount).format('0,0.00') + '€';
   }

   var isObj = function(a) {
  if ((!!a) && (a.constructor === Object)) {
    return true;
  }
  return false;
};
var _st = function(z, g) {
  return "" + (g != "" ? "[" : "") + z + (g != "" ? "]" : "");
};
var fromObject = function(params, skipobjects, prefix) {
  if (skipobjects === void 0) {
    skipobjects = false;
  }
  if (prefix === void 0) {
    prefix = "";
  }
  var result = "";
  if (typeof(params) != "object") {
    return prefix + "=" + encodeURIComponent(params) + "&";
  }
  for (var param in params) {
    var c = "" + prefix + _st(param, prefix);
    if (isObj(params[param]) && !skipobjects) {
      result += fromObject(params[param], false, "" + c);
    } else if (Array.isArray(params[param]) && !skipobjects) {
      params[param].forEach(function(item, ind) {
        result += fromObject(item, false, c + "[" + ind + "]");
      });
    } else {
      result += c + "=" + encodeURIComponent(params[param]) + "&";
    }
  }
  return result;
};

   const _handleSubmit = () => {
      const payload = {product: id, quantities, customizes};
      const u = fromObject(payload);
      window.location.replace('/cart/add?' + u);
   }

   return (
      <div className="configurator__sum">
         <ul>
            <li className="label">Textile</li>
            <li className="value product">{amountFormat(getProductsAmount())}</li>
         </ul>
         <ul>
            <li className="label">Personnalisation</li>
            <li className="value print">{amountFormat(getCustomizeAmount())}</li>
         </ul>
         <ul className="amount">
            <li className="label">Total HT</li>
            <li className="value amountWithoutTax">{amountFormat(getAmountWithoutTax())}</li>
         </ul>
         <ul>
            <li className="label">TVA (20%)</li>
            <li className="value amountTax">{amountFormat(getAmountTax())}</li>
         </ul>
         <ul className="amount">
            <li className="label">Total TTC</li>
            <li className="value amountWithTax">{amountFormat(getAmountWithTax())}</li>
         </ul>
         <ul>
            <li className="label">Total HT/unitaire</li>
            <li className="value amountWithoutTaxUnit">{amountFormat(getAmountWithoutTaxUnit())}</li>
         </ul>
         <button className={'btn btn-default'} onClick={() => _handleSubmit()}>Ajouter au panier</button>
      </div>
   );

}

export default Sum;
