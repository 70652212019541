import { render, unmountComponentAtNode } from 'react-dom';
import React, { useEffect, useRef, useState, useCallback, useContext } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import _ from 'lodash';

const Colors = ({colors, _handleSelectColor}) => {
   const getMainColor = (item) => {
      const color = _.first(item.colors);
      if(color){
         const {name, value} = color;
         if(_.size(value) == 13 && _.includes(value, '|')){
            return {
               name,
               values: _.split(value, '|')
            };
         } else {
            return {
               name,
               values: [
                  _.slice(color.value, 0, 6).join('')
               ]
            };
         }
      } else {
         return  {name: '', value: ''};
      }
   }
   const getItems = () => {
      return colors.map((item, index) => {
         const {name, values} = getMainColor(item);
         if(_.size(values) == 0){
            return null;
         }
         const renderTooltip = (props) => {
             return (
                <Tooltip id="button-tooltip" {...props}>{name}</Tooltip>
            );
         }
         const getClassItem = () => {
            return _.size(values) > 1 ? 'multiple' : 'single';
         }
         const getBlocksColor = () => {
            return values.map((hex) => {
               return (
                  <span style={{backgroundColor: '#' + hex}}></span>
               );
            });
         }
         return (
            <OverlayTrigger
               placement="top"
               delay={{ show: 0, hide: 0 }}
               overlay={renderTooltip}
            >
               <li key={index} className={getClassItem()} data-reference={item.reference} onClick={() => _handleSelectColor(item)} data-toggle="tooltip" data-placement="top">
                  <div>
                     {getBlocksColor()}
                  </div>
               </li>
            </OverlayTrigger>
         );
      });
   }
   return (
      <div className="configurator__colors">
         <div className="name">1. Séléctionnez votre couleur :</div>
         <ul className="colors">
            {getItems()}
         </ul>
      </div>
   );
}

export default Colors;
